
export const LOGIN_ERROR_MISSING_USERNAME_OR_PASSWORD = "auth/missing-username-or-password"
export const LOGIN_ERROR_INVALID_EMAIL = "auth/invalid-email"
export const LOGIN_ERROR_WRONG_PASSWORD = "auth/wrong-password"
export const LOGIN_ERROR_USER_NOT_FOUND = "auth/user-not-found"

export class LoginError{

    constructor(errorCode) {
        this.errorCode = errorCode
    }

    errorMessage = () => {
        
        switch (this.errorCode) {
            case LOGIN_ERROR_MISSING_USERNAME_OR_PASSWORD:
                return "Bitte Benutzernamen und Passwort eingeben!"
            case LOGIN_ERROR_INVALID_EMAIL:
                return "Bitte gültige E-Mail Adresse eingeben!"
            case LOGIN_ERROR_WRONG_PASSWORD:
            case LOGIN_ERROR_USER_NOT_FOUND:
                return "Benutzername und/oder Passwort sind nicht korrekt!"
            default:
                return "Unerwarteter Fehler beim Anmelden! Bitte versuche es später erneut!"
        }       
    }
}