
import { useState, useEffect } from 'react'
import CurrencyInput from "react-currency-input-field"
import OverviewTableRow from './OverviewTableRow'
import {styles} from './OverviewTableRow.styles'

let numberOfGamedays = 60


let generateGamedayRows = (gamedayValues, saveCallback, paymentChangedCallback) => {

    let gamedayRows = []
    for (let i = 0; i < numberOfGamedays; i++) {
        gamedayRows.push(
            <OverviewTableRow 
            key={i} 
            indexKey={i} 
            gamedayValues={gamedayValues} 
            paymentChangedCallback={paymentChangedCallback}
            saveCallback={saveCallback} />
        )
    }

    return gamedayRows
}

let calculateTotalPaymennt = (user, gamedayValues) => {

    var sum = 0
    for (const value of Object.values(gamedayValues)) {
        sum += value[user] === undefined ? 0 : value[user]
    }
    return sum
}

const OverviewTable = ({ gamedays, saveCallback, paymentChangedCallback}) => {

    const [gamedayValues, setGamedayValues] = useState(gamedays)
    const classes = styles()

    useEffect(() => {
        setGamedayValues(gamedays)
    }, [gamedays])

    return (
        <div class={classes.tableContainer}>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Domi</th>
                        <th>Jessy</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {generateGamedayRows(gamedayValues, saveCallback, paymentChangedCallback)}
                    <tr>
                        <td>Gesamt</td>
                        <td>
                            <CurrencyInput
                                defaultValue={0}
                                decimalScale={2}
                                suffix={" €"}
                                decimalsLimit={2}
                                fixedDecimalLength={2}
                                maxLength={3}
                                allowNegativeValue={false}
                                disabled={true}
                                decimalSeparator=","
                                groupSeparator="."
                                value={calculateTotalPaymennt('dominik', gamedayValues)}
                            />
                        </td>
                        <td>
                            <CurrencyInput
                                defaultValue={0}
                                decimalScale={2}
                                suffix={" €"}
                                decimalsLimit={2}
                                fixedDecimalLength={2}
                                maxLength={3}
                                allowNegativeValue={false}
                                disabled={true}
                                decimalSeparator=","
                                groupSeparator="."
                                value={calculateTotalPaymennt('jessica', gamedayValues)}
                            />
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default OverviewTable