import { createUseStyles } from "react-jss"

let styles = createUseStyles({
    finishedGamedayNumber: {
        color: 'lightgray !important'
    },
    finishedGamedaySelect: {
        color: 'lightgray !important',
        borderColor: 'lightgray !important'
    },
})

let renderSaveButton = (gamedayValues, i, saveCallback) => {

    if (gamedayValues[i] !== undefined && gamedayValues[i]['closed']) {
        return <button id={'s' + i} disabled>&#x2611;</button>
    }

    return <button id={'s' + i} onClick={() => saveCallback(gamedayValues, i)}>&#x2705;</button>
}

const OverviewTableRow = ({ indexKey, gamedayValues, paymentChangedCallback, saveCallback }) => {

    const classes = styles()

    return (
        <tr>
            <td class={(gamedayValues['d' + indexKey] !== undefined && gamedayValues[indexKey]['closed']) ? classes.finishedGamedayNumber : ''}>
                {indexKey + 1}
            </td>
            <td>
                <select name="payment" id={'d' + indexKey} class={(gamedayValues[indexKey] !== undefined && gamedayValues[indexKey]['closed']) ? classes.finishedGamedaySelect : ''} onChange={(e) => paymentChangedCallback(e.target.value, indexKey, 'dominik')} value={gamedayValues[indexKey] === undefined ? 0 : gamedayValues[indexKey]['dominik']} disabled={gamedayValues[indexKey] !== undefined && gamedayValues[indexKey]['closed']}>
                    <option value="0">0,00 €</option>
                    <option value="0.5">0,50 €</option>
                    <option value="1">1,00 €</option>
                    <option value="1.5">1,50 €</option>
                </select>
            </td>
            <td>
                <select name="payment" id={'j' + indexKey} class={(gamedayValues[indexKey] !== undefined && gamedayValues[indexKey]['closed']) ? classes.finishedGamedaySelect : ''} onChange={(e) => paymentChangedCallback(e.target.value, indexKey, 'jessica')} value={gamedayValues[indexKey] === undefined ? 0 : gamedayValues[indexKey]['jessica']} disabled={gamedayValues[indexKey] !== undefined && gamedayValues[indexKey]['closed']}>
                    <option value="0">0,00 €</option>
                    <option value="0.5">0,50 €</option>
                    <option value="1">1,00 €</option>
                    <option value="1.5">1,50 €</option>
                </select>
            </td>
            <td>
                {renderSaveButton(gamedayValues, indexKey, saveCallback)}
            </td>
        </tr>
    )
}


export default OverviewTableRow