import { useState, useEffect } from 'react'
import { getFirestore, collection, getDocs, doc, setDoc } from 'firebase/firestore';
import OverviewTable from "./OverviewTable";
import { styles } from './Overview.styles'

let loadGamedays = async (firebaseApp, setInputValues, setLoading) => {
    const db = getFirestore(firebaseApp);
    const gaamedaysCollection = collection(db, 'gamedays_web');
    const gamedaysSnapshot = await getDocs(gaamedaysCollection);

    let gamedayInputValues = {}

    gamedaysSnapshot.docs.forEach(document => {
       gamedayInputValues[document.id] = document.data()
    })

    setInputValues({ ...gamedayInputValues })
    setLoading(false)
}

let paymentChangedCallback = (gamedays, setGamedays, value, gamedayId, user) => {
    setGamedays({
        ...gamedays,
        [gamedayId]: {
            ...gamedays[gamedayId],
            [user]: Number(value),
            "closed": gamedays[gamedayId] !== undefined && gamedays[gamedayId]['closed'] ? true : false
        }
    })
}

let saveCallback = async (firebaseApp, setGamedays, gamedays, gamedayToUpdateId) => {

    const db = getFirestore(firebaseApp);

    const gamedayRef = doc(db, 'gamedays_web/' + gamedayToUpdateId)
    const gameday = {
        'dominik': gamedays[gamedayToUpdateId] === undefined || gamedays[gamedayToUpdateId]['dominik'] === undefined ? 0 : gamedays[gamedayToUpdateId]['dominik'],
        'jessica': gamedays[gamedayToUpdateId] === undefined || gamedays[gamedayToUpdateId]['jessica'] === undefined ? 0 : gamedays[gamedayToUpdateId]['jessica'],
        'closed': true
    }
    await setDoc(gamedayRef, gameday, { merge: true });
    setGamedays({ ...gamedays, [gamedayToUpdateId]: { ...gameday } })

}

let Overview = ({ firebaseApp }) => {

    let classes = styles()
    const [gamedays, setGamedays] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        loadGamedays(firebaseApp, setGamedays, setLoading)
    }, [firebaseApp])

    let componentToRender
    if (loading) {
        componentToRender = <span>Loading...</span>
    } else {
        componentToRender =
            <OverviewTable
                saveCallback={(allGamedays, gamedayToUpdateId) => saveCallback(firebaseApp, setGamedays, allGamedays, gamedayToUpdateId)}
                paymentChangedCallback={(paymentValue, gamedayId, user) => paymentChangedCallback(gamedays, setGamedays, paymentValue, gamedayId, user)}
                gamedays={gamedays}
            />
    }

    return (
        <div class={classes.overviewContainer}>
            {componentToRender}
        </div>
    )
}

export default Overview