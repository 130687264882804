import Login from './Components/Login/Login';
import { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Overview from './Components/Overview/Overview';

let firebaseApp = initializeApp({
  apiKey: "AIzaSyCgNbTVdR4JqgbVM4mqxgaBv1lHlLgQ_w8",
  authDomain: "tippspiel-d0c81.firebaseapp.com",
  projectId: "tippspiel-d0c81",
  storageBucket: "tippspiel-d0c81.appspot.com",
  messagingSenderId: "541867948925",
  appId: "1:541867948925:web:124ae7d00f12d7ac39aa14",
  measurementId: "G-EVCCQSD4JS"
})

function App() {

  let auth = getAuth()
  const [currentUser, setCurrentUser] = useState(auth.currentUser)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [auth])

  return (
    <div>
      {currentUser === null ? <Login firebaseApp={firebaseApp}/> : <Overview />}
    </div>
  );
}

export default App;
