
import React, { useState } from 'react';
import { styles } from './Login.style';
import penny_del_logo from '../../Images/penny-del-logo.png';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { LOGIN_ERROR_MISSING_USERNAME_OR_PASSWORD, LoginError } from './LoginError';

let handleInputChange = (e, setState) => {
    setState(e.target.value)
}

let onKeyDown = (e, username, password, setError) => {
    if (e.key === 'Enter') {
        login(username, password, setError)
    }
}

let login = (username, password, setError) => {
    let auth = getAuth()

    if (username === "" || password === "") {
        setError(new LoginError(LOGIN_ERROR_MISSING_USERNAME_OR_PASSWORD))
        return;
    }

    signInWithEmailAndPassword(auth, username, password)
        .catch((error) => {
            setError(new LoginError(error.code))
        })
}

let Login = () => {

    let classes = styles()
    let [username, setUsername] = useState("")
    let [password, setPasssword] = useState("")
    let [error, setError] = useState(null)

    return (
        <div class={classes.loginContainer}>
            <div class={classes.delLogoContainer}>
                <img src={penny_del_logo} alt="Penny DEL Logo" class={classes.delLogo} />
            </div>
            <div class={classes.loginLabelContainer}>
                <span class={classes.loginLabel}>Anmelden</span>
            </div>
            <div class={classes.usernameContainer}>
                <input id="username" class={classes.inputField} type="text" placeholder="Benutzername"
                    onChange={(e) => handleInputChange(e, setUsername)}
                    onKeyDown={(e) => onKeyDown(e, username, password, setError)}
                />
            </div>
            <div class={classes.passwordContainer}>
                <input id="password" class={classes.inputField} type="password" placeholder="Passwort"
                    onChange={(e) => handleInputChange(e, setPasssword)}
                    onKeyDown={(e) => onKeyDown(e, username, password, setError)}
                />
            </div>
            <div class={classes.loginButtonContainer}>
                <button class={classes.loginButton} onClick={() => login(username, password, setError)}>Login</button>
            </div>
            {error !== null &&
                <div class={classes.errorContainer}>
                    <span>{error.errorMessage()}</span>
                </div>
            }
        </div>
    )
}

export default Login